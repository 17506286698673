import {mapActions, mapGetters, mapMutations} from "vuex";
import {email, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "card-list",
  data() {
    return {
      currentCard: {},
    }
  },
  components: {},
  computed: {
    ...mapGetters({
      cardList: `payment/cardList`,
      cardInfo: `order/cardInfo`,
      cardLoader: `payment/cardLoader`
    }),
  },
  created() {
    this.fetchCardList()
    this.setData()
  },
  methods: {
    ...mapMutations({
      changePopupCardList: `order/CHANGE_POPUP_CARD_LIST`,
      changePopupCardAdd: `order/CHANGE_POPUP_CARD_PAY`,
      changeCardInfo: `order/CHANGE_CARD_INFO`,
      changeStepPay: `order/CHANGE_STEP_PAY`,

    }),
    ...mapActions({
      fetchCardList: `payment/GET_CARD_LIST`,
      deleteCard: `payment/DELETE_CARD`,
    }),
    cardDelete(id) {
      this.deleteCard({id: id}).then(() => {
        this.fetchCardList()
        this.$toasted.success(this.$t('successCardDeleted'))
      }).catch(e => {
        throw e
      })
    },
    setData() {
      Object.assign(this.currentCard, this.cardInfo)
    },
    submit() {
      this.changeCardInfo(this.currentCard)
      this.changePopupCardList(false)
      this.changeStepPay(true)
    }
  }
}